<template>
  <div class="container image-contain">
    <div class="container mt-5 "> 
      <div class="row">
        <div class="col border">
          <p class="para">History of Perfume in Netherlands:</p>
          <p class="paraph">Perfume has a longstanding history in the Netherlands, deeply intertwined with the country's 
            culture and commerce. Dating back centuries,
             the Dutch have been avid consumers and producers
              of fragrances, with a particular penchant for 
              exotic and luxurious scents.

              During the Golden Age of the Dutch Republic
              in the 17th century, Amsterdam emerged as a 
              bustling hub of trade and commerce, including
              the perfume trade. The Dutch East India 
              Company (VOC) played a pivotal role in 
              importing rare and exotic spices, herbs,
                and flowers from Asia and other parts of
                the world. These precious ingredients formed
                  the basis of many coveted perfumes that were
                   highly sought after by the Dutch elite.

                 The 18th and 19th centuries saw the flourishing
                 of the Dutch perfume industry, with the establishment
                  of renowned fragrance houses and perfumeries in cities
                   like Amsterdam, The Hague, and Rotterdam. Perfume production
                    became more sophisticated, with Dutch perfumers experimenting
                     with new ingredients and techniques to create unique and
                      alluring fragrances.

                  In the modern era, the Netherlands continues
                   to be a vibrant center for perfumery, with 
                   a thriving market for both mainstream and niche
                    fragrances. Dutch consumers have a discerning 
                    taste for high-quality scents, driving demand
                     for a wide range of perfumes from around the world.</p>
                     <br>
                  <p class="para">About BE Company:</p>
                  

                  <p class="paraph">BE Company is a pioneering fragrance company based in the Netherlands, dedicated to creating exceptional perfumes that capture the essence of Dutch culture and craftsmanship. Founded in 1947, BE Company has quickly gained recognition for its innovative approach to perfumery and commitment to sustainability and social responsibility.

                  Driven by a passion for storytelling and creativity, BE Company draws inspiration from the rich history and diverse landscapes of the Netherlands. Each perfume in their collection is meticulously crafted using the finest natural ingredients sourced from local Dutch suppliers and international partners.

                  With a focus on quality, authenticity, and environmental consciousness, BE Company takes pride in offering a curated selection of perfumes that reflect the unique character and spirit of the Netherlands. From refreshing citrus blends to aromatic floral bouquets, each fragrance tells a captivating story and evokes a sense of adventure and discovery.

                  At BE Company, they believe that perfume is more than just a scent – it's a journey, a memory, and a reflection of one's identity. With their commitment to excellence and innovation, BE Company is poised to become a leading player in the global fragrance industry, showcasing the best of Dutch creativity and ingenuity to the world.</p>
          
        </div>
      </div>  
    </div> 
  </div>
 </template> 
 
 <style>
  .container2 {
     width:100vw;
     height:calc(100vh - 68px);
     /* background-image: url('../assets/images/image9.jpeg'); */
     background-size: cover;
  } 
  .border {
    border: 3px solid rgb(34, 3, 65);
    border-radius: 20px;
    padding: 20px;
    text-align: justify;
  }
  .para{
    font-size: large;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    color:rgb(87, 10, 117)
    
  }
  .paraph {
    font-size: large;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman',
  }

 </style>
<template>
    <div class="background-perf"> 
     <div class="container">
       <div class="row justify-content-center">
         <div class="col-md-6 mt-3">
           <div class="card mb-3">
           <div class="row g-0">
             <div class="col-md-4">
               <img src="../assets/images/img1.jpeg" class="img-fluid rounded-start dynamic-height-image" alt="...">
             </div>
             <div class="col-md-8 bg-info-subtle">
               <div class="card-body">
                 <h5 class="card-title">Willem friderik</h5>
                 <p class="card-text">It is incredibly concentrated, submerging the iconic freshness of the perfume in the intoxicating spicy notes in the middle notes. A custom and special lavender extract and a blend of precious woods in this perfume have led to the creation of a powerful and majestic signature and an alluring and captivating trail.</p>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>
   <div class="container">
       <div class="row justify-content-center">
         <div class="col-md-6">
         <div class="card mb-3">
           <div class="row g-0">
             <div class="col-md-4">
               <img  src="../assets/images/img2.jpeg" class="img-fluid rounded-start dynamic-height-image" alt="...">
             </div>
             <div class="col-md-8 bg-danger-subtle">
               <div class="card-body">
                 <h5 class="card-title">Juliana</h5>
                 <p class="card-text">Warm and seductive, it consists of a wonderful powdery cashmere accord with a vague hint of vanilla and papyrus. Middle notes of cinnamon and nutmeg give it a spiciness, while a stimulating blend of tobacco flowers, pure laden, vanilla, coumarin and musk form the base of this intoxicating scent</p>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>
   <div class="container">
     <div class="row justify-content-center">
       <div class="col-md-6">
         <div class="card mb-3">
           <div class="row g-0">
             <div class="col-md-4">
               <img src="../assets/images/img3.jpeg" class="img-fluid rounded-start dynamic-height-image" alt="...">
             </div>
             <div class="col-md-8 bg-dark-subtle">
               <div class="card-body">
                 <h5 class="card-title">Willem alexander</h5>
                 <p class="card-text">This exceptional and very unique perfume has a very special, stylish fragrance with excellent fragrance distribution and a very long shelf life, which is due to the very special composition of this perfume consisting of leather, saffron, frankincense and incense.</p>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>
   <div class="container">
     <div class="row justify-content-center">
       <div class="col-md-6">
         <div class="card mb-3">
           <div class="row g-0">
             <div class="col-md-4">
               <img src="../assets/images/img4.jpeg" class="img-fluid rounded-start dynamic-height-image" alt="...">
             </div>
             <div class="col-md-8 bg-primary-subtle">
               <div class="card-body">
                 <h5 class="card-title">Wilhelmina</h5>
                 <p class="card-text">This cologne, which has a warm scent, starts with a prominent smell of two combinations of rose and raspberry in the initial note. Next, in the middle note, black gooseberry and pink pepper appear, and behind them, jasmine flowers and purple centaury flowers appear. Then in the final notes of vanilla, cedar and musk finish this perfume</p>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>
   </div> 
   
</template>
   
   <script>
   
   export default {
   }
   </script>
   <style scoped>

   .background-perf {
    background-color: rgb(66, 35, 95);
   }
   .dynamic-height-image{
    height:100%
   }
   </style>